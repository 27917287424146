import { colord } from "colord";

export const getAplhaString = (value, alpha, isRgba) => {
	if (isRgba === true) {
		if (alpha) {
			return colord(value).alpha(alpha).toRgbString();
		}
		else {
			return colord(value).toRgbString();
		}
	}
	else {
		if (alpha) {
			return colord(value).alpha(alpha).toHex();
		}
		else {
			return colord(value).toHex();
		}
	}
};