import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import BoxShadow from '../pages/boxshadow';

export default function AppRoutings() {
	return (
		<Router>
			<Header />
			<Switch>
				<Route exact path='/' component={BoxShadow} />
			</Switch>
		</Router>
	);
}
