import React, {useState, useRef, useCallback, useEffect} from 'react';
import styles from '../assets/scss/design.module.scss';
import { Slider, Switch, Select } from 'antd';
import { RgbaStringColorPicker, HexColorInput } from 'react-colorful';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import useClickOutside from "../utils/clickOutside";
import { getAplhaString } from '../utils/common';

const { Option } = Select;

function BoxShadow() {
	const shadow = {
		horizontal: 0,
		vertical: 2,
		blur: 41,
		spread: 2,
		color: '#000',
		opacity: 0.08
	};
	const popover = useRef();
	const [isOpen, toggle] = useState(false);
	const [cardColor, setCardColor] = useState('rgba(255,255,255)');
	const [isCardColorOpen, toggleCardColor] = useState(false);
	const [rgbaScheme, setRGBscheme] = useState(true);
	const close = useCallback(() => toggle(false), []);
	const closeCardColor = useCallback(() => toggleCardColor(false), []);
	const [defultPreset, setDefaultPreset] = useState('preset1');
	const [shadowData, setShadowData] = useState(shadow);
	useClickOutside(popover, close);
	useClickOutside(popover, closeCardColor);

	const onChange = (value, type) => {
		setDefaultPreset('custom');
		if (type==='color') {
			setShadowData({ ...shadowData, [type]: value });
			console.log(value);
		}
		else {
			setShadowData({ ...shadowData, [type]: value });
		}
	};

	const toggleColor = () => {
		setRGBscheme(!rgbaScheme);
	};

	const handleChange = (value) => {
		setDefaultPreset(value);
		if (value==='preset1') {
			setShadowData({
				horizontal: 0,
				vertical: 2,
				blur: 41,
				spread: 2,
				color: '#000',
				opacity: 0.08
			});
		}
		else if (value==='preset2') {
			setShadowData({
				horizontal: 0,
				vertical: 2,
				blur: 26,
				spread: 7,
				color: '#000',
				opacity: 0.17
			});
		}
		if (value==='preset3') {
			setShadowData({
				horizontal: 10,
				vertical: 10,
				blur: 0,
				spread: 7,
				color: '#000',
				opacity: 0.6
			});
		}
	};

	const shadowCode = `${shadowData.horizontal+`px `+shadowData.vertical+`px `+shadowData.blur+`px `+shadowData.spread+`px `+getAplhaString(shadowData.color,shadowData.opacity,rgbaScheme)}`;
	const code = `${`box-shadow: `+shadowCode+`;\n`+`-webkit-box-shadow: `+shadowCode+`;\n`+`-moz-box-shadow: `+shadowCode+`;`}`;

	return (
		<div className={styles.main_wrapper}>
			<div className={styles.configuration_box}>
				<div className={styles.mb_20}>
					<div className={styles.flex_spaced}>
						<div>Horizontal Length</div>
						<div>{shadowData.horizontal}</div>
					</div>
					<Slider min={-100} className={'config_slider'} defaultValue={shadowData.horizontal} onChange={(value) => onChange(value,'horizontal')} value={shadowData.horizontal}/>
				</div>
				<div className={styles.mb_20}>
					<div className={styles.flex_spaced}>
						<div>Vertical Length</div>
						<div>{shadowData.vertical}</div>
					</div>
					<Slider min={-100} className={'config_slider'} defaultValue={shadowData.vertical} onChange={(value) => onChange(value,'vertical')} value={shadowData.vertical}/>
				</div>
				<div className={styles.mb_20}>
					<div className={styles.flex_spaced}>
						<div>Blur Radius</div>
						<div>{shadowData.blur}</div>
					</div>
					<Slider className={'config_slider'} defaultValue={shadowData.blur} onChange={(value) => onChange(value,'blur')} value={shadowData.blur}/>
				</div>
				<div className={styles.mb_20}>
					<div className={styles.flex_spaced}>
						<div>Spread Radius</div>
						<div>{shadowData.spread}</div>
					</div>
					<Slider className={'config_slider'} defaultValue={shadowData.spread} onChange={(value) => onChange(value,'spread')} value={shadowData.spread}/>
				</div>
				<div className={styles.mb_20}>
					<div className={styles.flex_spaced}>
						<div>Shadow Opacity</div>
						<div>{shadowData.opacity}</div>
					</div>
					<Slider max={1} step={0.01} className={'config_slider'} defaultValue={shadowData.opacity} onChange={(value) => onChange(value,'opacity')} value={shadowData.opacity}/>
				</div>
				<div className={styles.mb_20} style={{width: 'max-content'}}>
					<div style={{display: 'flex'}}>
						<div className={styles.color_selector} onClick={() => toggle(true)} style={{background: shadowData.color}}/>
						<HexColorInput className={styles.color_input} color={shadowData.color} onChange={(value) => onChange(value,'color')} />
					</div>
					{isOpen && <div ref={popover} style={{position: 'absolute', zIndex: '1'}}>
						<RgbaStringColorPicker color={shadowData.color} onChange={(value) => onChange(value,'color')} />
					</div>}
				</div>
				<div className={styles.mb_20}>
					<div className={styles.mb_5}>Presets</div>
					<Select value={defultPreset} style={{ width: 200 }} onChange={handleChange}>
						<Option value="preset1">Soft Shadow</Option>
						<Option value="preset2">Dark Shadow</Option>
						<Option value="preset3">Retro Shadow</Option>
						<Option value="custom">Custom</Option>
					</Select>
				</div>
			</div>
			<div className={styles.right_section}>
				<div className={styles.flex_spaced}>
					<Switch checkedChildren="RGBA" unCheckedChildren="HEX" defaultChecked={rgbaScheme} onChange={toggleColor} />
					<div style={{width: 'max-content'}}>
						<div style={{display: 'flex'}}>
							<div className={styles.color_selector} onClick={() => toggleCardColor(true)} style={{background: cardColor}}/>
							<HexColorInput className={styles.color_input} color={cardColor} onChange={(value) => setCardColor(value)} />
						</div>
						{isCardColorOpen && <div ref={popover} style={{position: 'absolute', zIndex: '1'}}>
							<RgbaStringColorPicker color={cardColor} onChange={(value) => setCardColor(value)} />
						</div>}
					</div>
				</div>
				<div className={styles.shadowBox} style={{boxShadow: shadowCode, background: cardColor}}>
					<div>{getAplhaString(cardColor,null,rgbaScheme)}</div>
				</div>
				<SyntaxHighlighter language="css" style={dracula}>
					{code}
				</SyntaxHighlighter>
			</div>
		</div>
	);
}

export default BoxShadow;
