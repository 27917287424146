import React from 'react';
import styles from '../assets/scss/design.module.scss';
import LinkedIn from '../assets/images/linkedin.svg';
import Mail from '../assets/images/mail.svg';
import Medium from '../assets/images/medium.svg';

function Header() {
	return (
        <div>
            <div className={styles.header_section}>
                <div className={styles.name_logo}>darkside.</div>
                <div className={styles.social_icons}>
                    <a target="_blank" href='https://www.linkedin.com/in/arnab15c/'><img className={styles.mr_30} src={LinkedIn}/></a>
                    <a href="mailto:arnab.15c@gmail.com"><img style={{height: '14px'}} className={styles.mr_30} src={Mail}/></a>
                    <a target="_blank" href="https://medium.com/@arnab15c"><img style={{height: '14px'}} src={Medium}/></a>
                </div>
            </div>
        </div>
    );
}

export default Header;
